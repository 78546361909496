<script>
import Dropdown from '../inputs/select.vue'

export default {
  name: 'hours_dropdown',
  extends: Dropdown,
  props: {
    options: {
      type: Array,
      default () {
        return [
          { 'label': '7am', 'value': '7' },
          { 'label': '8am', 'value': '8' },
          { 'label': '9am', 'value': '9' },
          { 'label': '10am', 'value': '10' },
          { 'label': '11am', 'value': '11' },
          { 'label': '12am', 'value': '12' },
          { 'label': '1pm', 'value': '13' },
          { 'label': '2pm', 'value': '14' },
          { 'label': '3pm', 'value': '15' },
          { 'label': '4pm', 'value': '16' },
          { 'label': '5pm', 'value': '17' },
          { 'label': '6pm', 'value': '18' },
          { 'label': '7pm', 'value': '19' }
        ]
      }
    },
    label: {
      type: String,
      default: 'Hours'
    },
    name: {
      type: String,
      default: 'Hours'
    }
  }
}
</script>
